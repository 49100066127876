import './App.css';
import Event from './screens/event';
import Personaldata from './screens/personaldata';
import Checkout from './screens/checkout';
import Invite from './screens/invite';
import Index from './screens/index';
import Order from './screens/order';
import {observer, Provider} from "mobx-react";
import { configure } from "mobx"
import stores from "./stores";
import CookieConsent from "react-cookie-consent";
import OrganisationInfo from "./components/OrganisationInfo";

import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';
import Redirect from "./screens/redirect";
import React from "react";

import Organisation from "./screens/organisation";
import Error from "./components/error";
import Footer from "./components/footer";
import Ticket from "./screens/ticket";
import Queue from "./screens/queue";
import PreQueue from "./screens/preQueue";
import EventLayout from "./layouts/EventLayout";
import WaitingList from "./screens/waitingList";

configure({
  enforceActions: "never"
});

const ignorePatterns = [
  'PaymentAutofillConfig',
];

function App() {

  function inIframe () {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }


  if(inIframe() && window.location.hash === '#popup'){
    document.body.classList.add('popup');
  }

  if(inIframe()){
    document.body.classList.add('iframe');
  }

  const {DeviceStore} = stores;

  const declineCookies = () => {
    localStorage.CookieConent = false;
  }

  const acceptCookies = () => {
    localStorage.CookieConsent = true;
    DeviceStore.loadTrackers();
  }

  const {organisation_info, show_organisation_info, iframe, error, t, hasTrackers } = DeviceStore;

  return (
      <Provider { ...stores }>
        <div className={iframe ? 'in-iframe' : 'mb-10'}>
          {organisation_info && show_organisation_info ? <OrganisationInfo DeviceStore={DeviceStore}/> : null}
          {hasTrackers && localStorage.CookieConsent === undefined  ? <CookieConsent
              buttonText={t('accept')}
              declineButtonText={t('decline')}
              enableDeclineButton
              buttonStyle={{background: '#3982f6', color: '#fff', borderRadius: '5px'}}
              declineButtonStyle={{background: 'transparent', color: '#fff', fontSize: 'smaller', marginRight: 0}}
              onAccept={acceptCookies}
              onDecline={declineCookies}
          >
            {t('cookies')}
          </CookieConsent> : null }
          { error ? <Error/> : null }
          <div >

            <Router>
              <Routes>
                <Route exact path='/' element={< Index />}/>
                <Route exact path='/order/:hash' element={<Order/>}/>
                <Route exact path='/ticket/:hash' element={<Ticket/>}/>
                <Route exact path='/:organisation_slug' element={< Organisation />}/>
                <Route
                    path='/:organisation_slug/:shop_id'
                    element={<EventLayout/>}
                >
                  <Route exact path='/:organisation_slug/:shop_id' element={< Event />}/>
                  <Route exact path='/:organisation_slug/:shop_id/queue' element={< Queue />}/>
                  <Route exact path='/:organisation_slug/:shop_id/pre-queue' element={< PreQueue />}/>
                  <Route exact path='/:organisation_slug/:shop_id/registration' element={<Personaldata/>}/>
                  <Route exact path='/:organisation_slug/:shop_id/checkout' element={<Checkout/>}/>
                  <Route exact path='/:organisation_slug/:shop_id/invite' element={<Invite/>}/>
                  <Route exact path='/:organisation_slug/:shop_id/waiting-list' element={<WaitingList/>}/>
                  <Route exact path='/:organisation_slug/:shop_id/invite/:invite_id' element={<Invite/>}/>
                  <Route exact path='/:organisation_slug/:shop_id/redirect' element={<Redirect/>}/>
                </Route>
              </Routes>
              <Footer/>
            </Router>

          </div>
        </div>
      </Provider>
  );
}

export default observer(App);
