import React, {useEffect} from 'react';
import {observer, useLocalObservable} from "mobx-react-lite";
import {inject} from "mobx-react";
import {useParams, useNavigate} from "react-router-dom";
import moment from 'moment';
import DateInput from "../components/DateInput";
import {useSpring, animated} from "@react-spring/web";
import Timer from "../components/timer";
import PrimaryButton from "../components/PrimaryButton";

function Personaldata({DeviceStore}){

  const {organisation_slug, shop_id} = useParams();
  const navigate = useNavigate();

  const state = useLocalObservable(() => ({
    invalid: [],
    loading: false,
  }));

  const springs = useSpring({
    from: { x: 25, opacity: 0, },
    to: { x: 0, opacity: 1 },
  })

  const {t, formData, customFormData, locale, defaultLocale} = DeviceStore;

  useEffect(() => {
    if(!DeviceStore.reservation){
      navigate(`/${organisation_slug}/${shop_id}`, {replace: true})
      return
    }
    DeviceStore.setStep(2);
    document.title = t('personal_information') +' - ' + DeviceStore.eventData.event.name;
  }, []);



  if(!DeviceStore.eventData){
    return null;
  }

  const {form_fields, custom_form, event} = DeviceStore.eventData;

  const {theme} = DeviceStore;

  const goBack = () => {
    navigate(`/${organisation_slug}/${shop_id}`);
    if(DeviceStore.iframe) {
      window.scrollTo(0, 0);
      window.parent.postMessage({
        type: 'scrollTop',
      }, "*");
    }
  }

  const validForm = () => {
    DeviceStore.setError(null)
    let invalid = [];
    Object.values(form_fields).forEach(field => {
      let value = formData[field.name] ?? '';
      if(field.required && (!value || typeof value === 'string' && !value.trim())){
        invalid.push(field.name)
      }
    });
    if(custom_form) {
      custom_form_fields.forEach(field => {
        let value = customFormData[field.id] ?? '';
        if(field.required && (!value || typeof value === 'string' && !value.trim())){
          invalid.push(field.id);
        }
      })
    }
    if(formData.email !== formData.email_confirmation){
      invalid.push('email_confirmation')
      DeviceStore.setError(t('email_confirmation_error'));
    }

    if(formData.zipcode){
      const numbers = formData.zipcode.substring(0, 4);
      if(formData.zipcode.length < 4 || !/^\d+$/.test(numbers)) {
        invalid.push('zipcode');
        DeviceStore.setError(t('zipcode_confirmation_error'));
      }
    }

    if(event.age){
      const birthdate = formData.birthday ?? '';
      const age = moment(event.event_start).diff(birthdate, 'years', false);

      if(event.age > age){
        invalid.push('birthday')
        DeviceStore.setError(t('not_old_enough'))
      }

    }

    state.invalid = invalid;

    return !invalid.length;
  }

  const next = async (e) => {
    e.preventDefault();
    if(!validForm()){
      return;
    }

    state.loading = true;
    const valid = await DeviceStore.validateEmail();
    state.loading = false;
    const {organisation_slug, shop_id} = DeviceStore.eventData;
    if(valid){
      DeviceStore.cacheFormData();
      navigate(`/${organisation_slug}/${shop_id}/checkout`);
      if(DeviceStore.iframe) {
        window.scrollTo(0, 0);
        window.parent.postMessage({
          type: 'scrollTop',
        }, "*");
      }
    }
  }

  const renderInput = (field, invalid) => {

    let value;

    let customField = !!field.id;

    if(field.id){
      value = customFormData[field.id] ?? '';
    } else {
      value = formData[field.name] ?? '';
    }

    function setValue({target}){
      if(field.id){
        DeviceStore.setCustomFormValue(field.id, target.value)
      } else {
        DeviceStore.setFormValue(field.name, target.value)
      }
    }

    function setCheckboxValue(data, checked){
      if(!value){
        value = [];
      }
      if(checked){
        value.push(data);
      } else {
        value = value.filter(v => v !== data);
      }
      DeviceStore.setCustomFormValue(field.id, value)
    }

    const name = field.id ? 'custom['+field.id+']' : field.name;

    if(field.type === 'checkbox'){

      if(!value){
        value = [];
      }

      return (
          <div>
            {field.options.map((option, i) => {
              return (
                  <div key={i} className="relative flex items-start">
                    <div className="flex items-center h-5">
                      <input
                          value={option[defaultLocale]}
                          onChange={({target}) => setCheckboxValue(option[defaultLocale], target.checked)}
                          id={'checkbox_'+field.id+'_'+i}
                          name={'checkbox_'+field.id}
                          checked={value.includes(option[defaultLocale])}
                          required={field.required && !value.length}
                          type="checkbox"
                          className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded dark:bg-gray-950"
                      />
                    </div>
                    <div className="ml-3 text-sm">
                      <label htmlFor={'checkbox_'+field.id+'_'+i} className="font-medium text-gray-700 dark:text-gray-300">
                        {option[locale] ?? option[defaultLocale]}
                      </label>
                    </div>
                  </div>
              )
            })}
          </div>
      )
    }

    if(field.type === 'select'){
      return (
          <select value={value} onChange={setValue} name={name} id={name} required={field.required} className={'max-w-lg block focus:ring-blue-500 focus:border-blue-500 w-full shadow-sm sm:max-w-xs sm:text-sm rounded-md dark:bg-gray-950 dark:text-white ' + (invalid ? 'border-red-500' : 'border-gray-300 dark:border-gray-700')}>
            <option value="">{t('choose')}</option>
            {Object.entries(field.options).map(option => {
              const key = customField ? (option[1][locale] ?? option[1][defaultLocale]) : option[0];
              return <option key={key} value={key}>{ option[1][locale] ?? option[1][defaultLocale] }</option>
            })}
          </select>
      )
    }

    if(field.type === 'date'){
      return <DateInput name={name} id={name} invalid={invalid} required={field.required} onChange={setValue} value={value} />
    }

    return <input value={value} onChange={setValue} type={field.type} name={name} id={name} required={field.required} className={'max-w-lg block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md  dark:bg-gray-950 dark:text-white ' + (invalid ? 'border-red-500' : 'border-gray-300 dark:border-gray-700')}/>
  }

  const renderField = (field) => {

    let label = field.label[locale] ?? field.label[defaultLocale];

    let description = '';

    if(field.description){
      description = field.description[locale] ?? field.description[defaultLocale];
    }

    let key = field.id ?? field.name;

    let invalid = state.invalid.includes(key);

    let className = 'sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-4 dark:border-gray-700';

    if(description && description.length > 100){
      className = '';
    }

    const name = field.id ? 'custom['+field.id+']' : field.name;

    return (
        <div key={key} className={className}>
          <label htmlFor={name} className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 dark:text-gray-300">
            <span className={'form-field-label ' + (invalid ? 'text-red-500' : '')}>{label}{field.required ? <span className="required text-red-500">*</span>: ''}</span>
            {description ? <p className="form-field-description text-sm font-normal mt-1 mb-2" dangerouslySetInnerHTML={{ __html: description.replace(/(?:\r\n|\r|\n)/g, '<br>') }}/> : null }
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            { renderInput(field, invalid) }
          </div>
        </div>
    );
  }

  const renderForm = () => {
    return Object.values(form_fields).map(field => renderField(field))
  }

  const ticketIds = DeviceStore.selectedTickets.map(entry => entry.tickettype_id)

  const custom_form_fields = custom_form ? custom_form.fields.filter(field => {
    if(!field.ticket_dependency || !field.ticket_dependency.length){
      return true;
    }

    return !!field.ticket_dependency.filter(id => ticketIds.includes(id)).length;

  }) : [];

  const renderCustomForm = () => {
    return custom_form_fields.map(field => renderField(field))
  }

  return (
      <animated.form style={springs} onSubmit={next}>
        <div className="bg-white rounded-lg shadow-md overflow-hidden border border-gray-200 dark:bg-gray-900 dark:border-gray-700">
          <div className="px-4 sm:px-5 p-5 space-y-4">
            <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100">
              {t('personal_information')}
            </h3>
            { renderForm() }

            {custom_form_fields.length ? <>
              <h3 className="text-lg leading-6 font-medium text-gray-900 border-t pt-8 dark:border-gray-700 dark:text-gray-100">
                {t('extra_information')}
              </h3>
              { renderCustomForm() }
            </> : null}
          </div>

          <div className="bottom-nav p-4 flex justify-between items-center border-t bg-gray-50 dark:bg-gray-800 dark:border-gray-700">
            <div>
              <button type="button" onClick={goBack} className="back-btn text-sm text-gray-800 cursor-pointer dark:text-gray-200">{t('back')}</button>
            </div>
            <div className="flex items-center">
              <Timer/>
              <PrimaryButton loading={state.loading} theme={theme} label={t('next')}/>
            </div>
          </div>
        </div>
      </animated.form>
  )
}

export default inject("DeviceStore")(observer(Personaldata));
