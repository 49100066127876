import React, {useEffect} from 'react';
import {useParams} from "react-router-dom";
import axios from "axios";
import {useLocalObservable, observer} from "mobx-react-lite";
import Loader from "../components/loader";
import {CalendarIcon, LocationMarkerIcon} from "@heroicons/react/solid";
import NotFound from "../components/notFound";
import ReactPixel from "react-facebook-pixel";
import TagManager from "react-gtm-module";
import {inject} from "mobx-react";
import TicketForm from "../components/order/TicketForm";
import Modal from "../components/modal";
import ModalButton from "../components/ModalButton";
import QRCode from "react-qr-code";
import ErrorView from "../components/errorView";

function Order({DeviceStore}){

  const {apiUrl} = DeviceStore;

  const {hash} = useParams();

  const {t, setLocale} = DeviceStore;

  const created = window.location.search.includes('created=1');

  const state = useLocalObservable(() => ({
    notFound: false,
    copied: false,
    loading: false,
    loadingTicket: null,
    data: null,
    modal: null,
    ticketModal: null,
    error: false,
    setField(barcode, key, value){
      this.data.tickets = this.data.tickets.map(t => {
        if(t.barcode === barcode){
          t[key] = value;
        }
        return t;
      })
    },
  }));

  useEffect(() => {
    getOrder();
  },[]);

  /**
   * Get the order from the API
   *
   * @returns {Promise<void>}
   */
  const getOrder = async () => {
    state.loading = true;
    try {
      const {data} = await axios.get(apiUrl + '/shop/order/' + hash);
      state.data = data;
      loadFbPixel();
      loadTagManager();

      if(data.lang) {
        setLocale(data.lang);
      }

      document.title = t('order') +' ' + data.id + ' - ' + state.data.event.name;

      if(data.theme){
        DeviceStore.setTheme(data.theme);
        DeviceStore.setForceFooter()
      }

    } catch (e){
      if(e.response?.status === 404){
        state.notFound = true;
      } else {
        state.error = true;
      }
    }
    state.loading = false
  }

  const mustPersonalizeError = () => {
    alert('Je moet eerst alle tickets personaliseren.');
  }

  /**
   * Update the ticket name
   *
   * @param e
   * @param data
   * @param barcode
   * @returns {Promise<boolean>}
   */
  const updateNames = async (e, data, barcode) => {
    e.preventDefault();
    if(state.loadingTicket){
      return;
    }

    Object.entries(data).forEach(entry => {
      state.setField(barcode, entry[0], entry[1])
    })

    const ticket = tickets.find(t => t.barcode === barcode);

    state.loadingTicket = barcode;
    try {
      await axios.post(apiUrl + '/shop/ticket/' + ticket.hash + '/update', ticket);
    } catch (e){
      DeviceStore.setError(e?.response?.data?.message || 'Er ging iets fout, probeer het nog een keer.');
      state.loadingTicket = null;
      return false;
    }
    state.loadingTicket = null;
    return true;
  }

  /**
   * Copy the order url
   */
  const copyUrl = () => {
    var copyText = document.getElementById("currentUrl");

    // Select the text field
    copyText.select();
    copyText.setSelectionRange(0, 99999); // For mobile devices

    // Copy the text inside the text field
    navigator.clipboard.writeText(copyText.value);

    state.copied = true;
    setTimeout(() => {
      state.copied = false;
    }, 5000)
  }

  /**
   * Create an invoice for the order
   *
   * @param e
   * @returns {Promise<void>}
   */
  const createInvoice = async (e) => {
    e.preventDefault();
    try {
      const {data} = await axios.post(apiUrl + '/shop/order/' + hash + '/create-invoice', {
        company_name: state.data.company_name,
        address: state.data.address,
        zipcode: state.data.zipcode,
        city: state.data.city,
      });
      state.data.invoice = data.url;
      state.modal = null;
      window.open(data.url, '_blank');
    } catch (e) {
      console.warn(e)
    }
  }

  /**
   * Check if a ticket can be downloaded
   *
   * @param ticket
   * @returns {boolean}
   */
  const canDownloadTicket = (ticket) => {
    let canDownload = true;

    if(personal) {
      if(!ticket.voucher && !ticket.disable_personalization) {
        Object.values(state.data.ticket_fields).forEach(field => {
          if (!ticket[field.name] && field.required) {
            canDownload = false;
          }
        })
      }
    }
    return canDownload;
  }

  /**
   * Load Google Tag Manager
   */
  const loadTagManager = () => {
    if(!state.data.event.tagmanager) return;

    if(state.data.event.tagmanager.toLowerCase().startsWith('gtm-')) {
      TagManager.initialize({
        gtmId: state.data.event.tagmanager,
        dataLayer: {
          event: 'Order ' + (created ? state.data.status : 'opened'),
          orderId: state.data.id,
        }
      })
    } else {
      window.gtag('config', state.data.event.tagmanager);
    }


    if(created && state.data.status === 'paid' && window.gtag) {
      setTimeout(() => {
        window.gtag('event', 'purchase', {
          transaction_id: state.data.id,
          currency: 'EUR',
          value: state.data.price,
          items: state.data.tickets.map(ticket => {
            return {
              item_id: ticket.id,
              item_name: ticket.name,
              price: ticket.price,
              quantity: 1
            }
          })
        });
      }, 1000)
    }

  }

  /**
   * Load the FB pxiel
   */
  const loadFbPixel = () => {
    if (!state.data.event.fb_pixel) return;
    ReactPixel.init(state.data.event.fb_pixel, {}, {
      autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
      debug: false, // enable logs
    });
    ReactPixel.pageView();

    if(created && state.data.status === 'paid') {
      ReactPixel.track('Purchase', {
        value: state.data.price,
        currency: 'EUR',
      });
    }
  }

  if(state.error){
    return (
        <ErrorView/>
    )
  }

  if(state.notFound){
    return (<NotFound title="We konden de bestelling niet vinden."/>)
  }

  if(state.loading || !state.data){
    return (<Loader/>)
  }

  const {event, download, tickets, status, id, email, invoice, price} = state.data;

  let {first_name} = state.data;

  if(first_name === 'Deurverkoop'){
    first_name = '';
  } else {
    first_name = ' ' + first_name;
  }

  const personal = state.data.ticket_fields && tickets.filter(t => !t.voucher && !t.disable_personalization).length > 0;

  let {theme} = DeviceStore;

  let required_ticket_info = false;

  let sortedTickets = {};
  tickets.forEach(ticket => {
    if (personal && !ticket.voucher && !ticket.disable_personalization) {
      Object.values(state.data.ticket_fields).forEach(f => {
        if (!ticket[f.name]) {
          required_ticket_info = true;
        }
      })
    }
    if(!sortedTickets[ticket.name]){
      sortedTickets[ticket.name] = {
        name: ticket.name,
        tickets: []
      }
    }
    sortedTickets[ticket.name].tickets.push(ticket)
  })

  sortedTickets = Object.values(sortedTickets);

  const eventExpired = Math.floor(Date.now() / 1000) > event.event_end + (3600 * 24 * 30);

  function renderOpen(){
    return (
        <div className="rounded-md bg-white dark:bg-gray-900 dark:border-gray-700 shadow border p-4 dark:text-white">
          <h2 className="mb-4 order-content-salutation">Beste {first_name},</h2>
          <p className="order-content-text text-sm">Je bestelling wordt momenteel nog verwerkt, zodra je bestelling verwerkt is ontvang je hier een e-mail van.</p>
        </div>
    )
  }

  function renderCancelled(){
    return (
        <div className="rounded-md bg-white dark:bg-gray-900 dark:border-gray-700 shadow border p-4 dark:text-white">
          <h2 className="mb-4 order-content-salutation">Beste {first_name},</h2>
          <p className="order-content-text text-sm">Je hebt de bestelling geannuleerd.</p>
        </div>
    )
  }

  function renderRefunded(){
    return (
        <div className="rounded-md bg-white dark:bg-gray-900 dark:border-gray-700 shadow border p-4 dark:text-white">
          <h2 className="mb-4 order-content-salutation">Beste {first_name},</h2>
          <p className="order-content-text text-sm">Je bestelling is geannuleerd en terug gestort.</p>
        </div>
    )
  }

  function renderPaid(){

    return (
        <div className="space-y-4">

          <div className="rounded-md bg-white dark:bg-gray-900 dark:border-gray-700 shadow border p-4 dark:text-white">

            {created && (email.includes('live') || email.includes('hotmail') || email.includes('outlook')) ? <p className="p-2 px-3 mb-4 bg-blue-100 text-gray-800 rounded text-sm">We hebben je tickets ook via de mail verstuurd. Niet ontvangen? Check dan ook even je spamfolder.</p> : null}

            <h2 className="text-lg mb-2 order-content-salutation">{t('dear')}{first_name},</h2>
            {event.order_description ? <p className="order-content-text" dangerouslySetInnerHTML={{__html: event.order_description}}></p> : <p className="order-content-text text-sm">
              {t('tickets_for')} {event.name}. {t('order_description')}
            </p>}
            {eventExpired ? <div>
              <p className="text-white p-4 bg-red-500 rounded-md mt-4 font-bold">{t('event_ended')}</p>
            </div> : null}

          </div>

          <div className="order-info rounded-md bg-white dark:bg-gray-900 dark:border-gray-700 shadow border p-2">
            <div className="sm:flex justify-between">
              <div className="p-2 dark:text-white text-sm">
                <table className="pr-2">
                  <tbody>
                  <tr>
                    <td className="pr-2">{t('order_number')}:</td>
                    <td>{id}</td>
                  </tr>
                  {state.data.price ? <tr>
                    <td className="pr-2">{t('costs')}:</td>
                    <td>{new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(price)}</td>
                  </tr> : null }
                  {state.data.company_name ?
                      <tr>
                        <td className="pr-2">{t('company_name')}:</td>
                        <td>{state.data.company_name}</td>
                      </tr> : null }
                  <tr>
                    <td className="pr-2">{t('name')}:</td>
                    <td>{state.data.first_name + ' ' + state.data.last_name}</td>
                  </tr>
                  <tr>
                    <td className="pr-2">{t('email')}:</td>
                    <td>{state.data.email}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
              {!eventExpired ? <div className="order-info-buttons p-2 space-y-2">
                <div>
                  {!required_ticket_info ? <a href={download} target="_blank" rel="noreferrer" className={'p-4 py-2 font-bold text-sm rounded-md block bg-blue-600 ' + (theme.primary_light ? 'text-black' : 'text-white')}  style={{backgroundColor: theme.primary_color}}>
                    {t('download_all_tickets')}
                  </a> : <button type="button" onClick={mustPersonalizeError} className={'p-4 py-2 font-bold text-sm rounded-md block bg-blue-600 cursor-not-allowed opacity-50 ' + (theme.primary_light ? 'text-black' : 'text-white')}  style={{backgroundColor: theme.primary_color}}>
                    {t('download_all_tickets')}
                  </button> }
                </div>
                <button type="button" onClick={() => state.modal = 'share'} className={'p-4 py-2 font-bold text-sm rounded-md w-full bg-gray-200 text-gray-900 dark:bg-gray-700 dark:text-gray-100 flex justify-between items-center'}>
                  {t('share')}
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4">
                    <path d="M13 4.5a2.5 2.5 0 11.702 1.737L6.97 9.604a2.518 2.518 0 010 .792l6.733 3.367a2.5 2.5 0 11-.671 1.341l-6.733-3.367a2.5 2.5 0 110-3.475l6.733-3.366A2.52 2.52 0 0113 4.5z" />
                  </svg>
                </button>
                {state.data.price ? <>
                  {invoice ? <a href={invoice} target="_blank" rel="noreferrer" className={'p-4 py-2 font-bold text-sm rounded-md w-full bg-gray-200 text-gray-900  dark:bg-gray-700 dark:text-gray-100 flex justify-between items-center'}>
                    {t('invoice')}
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4">
                      <path fillRule="evenodd" d="M4.5 2A1.5 1.5 0 003 3.5v13A1.5 1.5 0 004.5 18h11a1.5 1.5 0 001.5-1.5V7.621a1.5 1.5 0 00-.44-1.06l-4.12-4.122A1.5 1.5 0 0011.378 2H4.5zm4.75 6.75a.75.75 0 011.5 0v2.546l.943-1.048a.75.75 0 011.114 1.004l-2.25 2.5a.75.75 0 01-1.114 0l-2.25-2.5a.75.75 0 111.114-1.004l.943 1.048V8.75z" clipRule="evenodd" />
                    </svg>
                  </a> : <button type="button" onClick={() => state.modal = 'invoice'} className={'p-4 py-2 font-bold text-sm rounded-md w-full bg-gray-200 text-gray-900  dark:bg-gray-700 dark:text-gray-100 flex justify-between items-center'}>
                    {t('invoice')}
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4">
                      <path fillRule="evenodd" d="M4.5 2A1.5 1.5 0 003 3.5v13A1.5 1.5 0 004.5 18h11a1.5 1.5 0 001.5-1.5V7.621a1.5 1.5 0 00-.44-1.06l-4.12-4.122A1.5 1.5 0 0011.378 2H4.5zm4.75 6.75a.75.75 0 011.5 0v2.546l.943-1.048a.75.75 0 011.114 1.004l-2.25 2.5a.75.75 0 01-1.114 0l-2.25-2.5a.75.75 0 111.114-1.004l.943 1.048V8.75z" clipRule="evenodd" />
                    </svg>
                  </button> }
                </> : null }
              </div>: null }
            </div>
          </div>

          { sortedTickets.map(group => {
            return (
                <div key={group.name} className="ticket-section rounded-md bg-white dark:bg-gray-900 dark:border-gray-700 shadow border">
                  <h2 className="p-4 border-b dark:border-gray-700 text-xl dark:text-white font-bold">
                    {group.name}
                    {event.type === 'recurring' ? <span className="block text-sm text-gray-500 font-normal">{event.date}</span> : null }
                  </h2>
                  <div className="divide-y dark:divide-gray-700">
                    { group.tickets.map((ticket, i) => {

                      const canDownload = canDownloadTicket(ticket)

                      return (
                          <div key={ticket.barcode} className="p-4 flex justify-between items-center dark:text-white">
                            <div>
                              <h3 className="font-bold">{t('ticket')} {i + 1}{ ticket.variant ? <span className="text-sm"> ({ticket.variant})</span> : null } {ticket.seat ? <span className="text-sm">({ticket.seat.name})</span> : null}</h3>
                              <h4 className="text-sm text-gray-500 dark:text-gray-300">
                                {personal && !ticket.voucher ? <>
                                  {ticket.first_name && ticket.last_name ? ticket.first_name + ' ' + ticket.last_name : <span className="text-red-500">{t('not_yet_personalised')}</span> }
                                </> : ticket.barcode}
                              </h4>
                            </div>
                            {!eventExpired ? <button type="button" onClick={() => state.ticketModal = ticket.barcode} className={'p-4 py-2 font-bold text-sm rounded-md bg-blue-600 ' + (theme.primary_light ? 'text-black' : 'text-white')}  style={{backgroundColor: theme.primary_color}}>
                              {t('show_ticket')}
                            </button> : null }
                            <Modal visible={state.ticketModal === ticket.barcode} theme={theme} onClose={() => state.ticketModal = null}>
                              <div className="">
                                <h2 className="text-xl border-b dark:border-gray-700 p-4">{group.name}</h2>
                              </div>
                              <div className="p-4 pb-0">

                               <div className="sm:flex sm:justify-between mb-6 sm:mb-4">
                                 <div className="text-center sm:text-left">
                                   <h3 className="font-bold dark:text-white">{t('ticket')} {i + 1}{ ticket.variant ? <span className="text-sm"> ({ticket.variant})</span> : null }</h3>
                                   <p>{ticket.seat ? ticket.seat.name : null}</p>
                                   { personal ? <p>{ticket.first_name} {ticket.last_name}</p> : null }
                                   <p>{ticket.barcode}</p>
                                 </div>
                                 <div className="flex justify-center">
                                   <div className="mt-4 sm:mt-0 w-60 sm:w-52">
                                     <div className="border-2 p-4 bg-white rounded-md">
                                       <QRCode
                                         style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                         size={256}
                                         className={canDownload ? '' : 'blur opacity-75'}
                                         value={ticket.barcode}
                                       />
                                     </div>
                                   </div>
                                 </div>
                               </div>

                                {!canDownload ? <div className="p-4 bg-red-500 text-white rounded-md mb-2">{t('ticket_available_after_personalization')}</div> : null}

                                 <div className="grid gap-2 sm:grid-cols-1">
                                   {canDownload ? <>
                                  <ModalButton
                                    theme={theme}
                                    label={t('download_ticket') + ' (PDF)'}
                                    href={ticket.pdf}
                                    icon={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                      <path strokeLinecap="round" strokeLinejoin="round" d="M9 8.25H7.5a2.25 2.25 0 00-2.25 2.25v9a2.25 2.25 0 002.25 2.25h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25H15M9 12l3 3m0 0l3-3m-3 3V2.25" />
                                    </svg>}
                                  />
                                  <ModalButton
                                    theme={theme}
                                    label={t('add_to_wallet')}
                                    href={ticket.pkpass}
                                    icon={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                      <path strokeLinecap="round" strokeLinejoin="round" d="M21 12a2.25 2.25 0 00-2.25-2.25H15a3 3 0 11-6 0H5.25A2.25 2.25 0 003 12m18 0v6a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 18v-6m18 0V9M3 12V9m18 0a2.25 2.25 0 00-2.25-2.25H5.25A2.25 2.25 0 003 9m18 0V6a2.25 2.25 0 00-2.25-2.25H5.25A2.25 2.25 0 003 6v3" />
                                    </svg>}
                                  />
                                       </> : null }

                                   {personal && !ticket.voucher ? <TicketForm
                                       key={i}
                                       number={i+1}
                                       editable={ticket.can_edit}
                                       fields={state.data.ticket_fields}
                                       expanded={!ticket.first_name}
                                       loading={state.loadingTicket === ticket.barcode}
                                       setField={state.setField}
                                       onSubmit={(e, data) => updateNames(e, data, ticket.barcode)}
                                       ticket={ticket}
                                   /> : null }

                                  <ModalButton
                                   theme={theme}
                                   label={t('share_whatsapp')}
                                   href={'whatsapp://send?text='+encodeURIComponent('Hey, hierbij jouw ticket voor *'+event.name+'*: ' + ticket.url)}
                                   icon={<svg fill="currentColor" viewBox="0 0 30 30" className="w-6 h-6"><path fillRule="evenodd" d="M24.504 7.504A11.875 11.875 0 0 0 16.05 4C9.465 4 4.1 9.36 4.1 15.945a11.882 11.882 0 0 0 1.594 5.973L4 28.109l6.336-1.664a11.958 11.958 0 0 0 5.71 1.457h.005c6.586 0 11.945-5.359 11.949-11.949 0-3.191-1.242-6.191-3.496-8.45ZM16.05 25.883h-.004a9.93 9.93 0 0 1-5.055-1.383l-.363-.215-3.762.985 1.004-3.665-.234-.375a9.904 9.904 0 0 1-1.52-5.285c0-5.472 4.457-9.925 9.938-9.925a9.863 9.863 0 0 1 7.02 2.91 9.875 9.875 0 0 1 2.905 7.023c0 5.477-4.457 9.93-9.93 9.93Zm5.445-7.438c-.297-.148-1.766-.87-2.039-.968-.273-.102-.473-.149-.672.148-.2.3-.77.973-.945 1.172-.172.195-.348.223-.645.074-.3-.148-1.261-.465-2.402-1.484-.887-.79-1.488-1.77-1.66-2.067-.176-.3-.02-.46.129-.61.136-.132.3-.347.449-.523.148-.171.2-.296.3-.496.098-.199.048-.375-.027-.523-.074-.148-.671-1.621-.921-2.219-.243-.582-.489-.5-.672-.511-.172-.008-.371-.008-.57-.008-.2 0-.524.074-.798.375-.273.297-1.043 1.02-1.043 2.488 0 1.469 1.07 2.89 1.22 3.09.148.195 2.105 3.21 5.1 4.504.712.308 1.266.492 1.7.629.715.226 1.367.195 1.883.12.574-.085 1.765-.722 2.015-1.421.247-.695.247-1.293.172-1.418-.074-.125-.273-.2-.574-.352Z"/></svg>}
                                  />
                                </div>

                              </div>
                            </Modal>
                          </div>
                      )
                    }) }
                  </div>
                </div>
            )
          }) }

          <div className="tips-section rounded-md bg-white dark:bg-gray-900 dark:border-gray-700 shadow border p-4">
            <h4 className="text-xl mb-4 dark:text-white">{t('tips_title')}</h4>
            <div className="space-y-3 tips" dangerouslySetInnerHTML={{__html: t('tips')}}/>
          </div>

        </div>
    )
  }

  const eventLocation = event.location;

  const locationLabel = eventLocation ? [eventLocation.name, eventLocation.city].filter(v => v).join(', ') : null;
  const locationAddress = eventLocation ? [eventLocation.address ?? eventLocation.name,  (eventLocation.zipcode ? (eventLocation.zipcode + ' ') : '')  + eventLocation.city].filter(v => v).join(', ') : null;


  return (
      <div className="container">

        <div className="event-header overflow-hidden shadow-md my-4 rounded-md ">
          {theme.image ?
              <div className="shop-header bg-gray-800 text-white relative">
                <img src={theme.image} alt="" className="block w-full object-cover"/>
              </div> : null }
          <div className="block p-4 py-3 bg-blue-600 relative " style={{backgroundColor: theme.primary_color}}>
            <h1 className={'event-title text-xl font-semibold mb-1 md:mb-0 ' + (theme.primary_light ? 'text-black' : 'text-white')}>{event.name}</h1>
            <div className={'md:flex items-center space-y-1 md:space-y-0 md:space-x-4 text-sm ' + (theme.primary_light ? 'text-black' : 'text-white')}>
              <h2 className="event-date flex items-center">
                <CalendarIcon className="h-4 mr-2"/>
                {event.date ?? 'Datum nog niet bekend'}
              </h2>
              {locationLabel ?
                  <a href={'https://www.google.nl/maps/search/'+locationAddress.replace(/\s+/g, '+')} target="_blank" rel="noreferrer">
                    <h3 className="event-location flex items-center">
                      <LocationMarkerIcon className="h-4 mr-2"/>
                      {locationLabel}
                    </h3>
                  </a> : null }
            </div>
            <button type="button" className="text-white absolute right-2 bottom-2 opacity-75">

            </button>
          </div>
        </div>

        {status === 'open' ? renderOpen() : null}

        {status === 'cancelled' ? renderCancelled() : null}

        {status === 'refunded' ? renderRefunded() : null}

        {status === 'paid' ? renderPaid() : null}

        <Modal visible={state.modal === 'share'} theme={theme} onClose={() => state.modal = null}>
          <div className="p-4 space-y-2 pb-0">
            <input id="currentUrl" type="text" className="w-full rounded-tl-md rounded-bl-md text-xs hidden" readOnly={true} value={window.location.href.replace('&created=1', '')}/>
            <ModalButton
              label={state.copied ? t('link_copied') : t('copy')}
              theme={theme}
              icon={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M15.666 3.888A2.25 2.25 0 0013.5 2.25h-3c-1.03 0-1.9.693-2.166 1.638m7.332 0c.055.194.084.4.084.612v0a.75.75 0 01-.75.75H9a.75.75 0 01-.75-.75v0c0-.212.03-.418.084-.612m7.332 0c.646.049 1.288.11 1.927.184 1.1.128 1.907 1.077 1.907 2.185V19.5a2.25 2.25 0 01-2.25 2.25H6.75A2.25 2.25 0 014.5 19.5V6.257c0-1.108.806-2.057 1.907-2.185a48.208 48.208 0 011.927-.184" />
              </svg>}
              onClick={copyUrl}
            />
            <ModalButton
                label={t('share_whatsapp')}
                theme={theme}
                href={'whatsapp://send?text='+encodeURIComponent('Hierbij de tickets voor ' + event.name + ': ' + window.location.href.replace('&created=1', ''))}
                icon={<svg fill="currentColor" viewBox="0 0 30 30" className="w-6 h-6"><path fillRule="evenodd" d="M24.504 7.504A11.875 11.875 0 0 0 16.05 4C9.465 4 4.1 9.36 4.1 15.945a11.882 11.882 0 0 0 1.594 5.973L4 28.109l6.336-1.664a11.958 11.958 0 0 0 5.71 1.457h.005c6.586 0 11.945-5.359 11.949-11.949 0-3.191-1.242-6.191-3.496-8.45ZM16.05 25.883h-.004a9.93 9.93 0 0 1-5.055-1.383l-.363-.215-3.762.985 1.004-3.665-.234-.375a9.904 9.904 0 0 1-1.52-5.285c0-5.472 4.457-9.925 9.938-9.925a9.863 9.863 0 0 1 7.02 2.91 9.875 9.875 0 0 1 2.905 7.023c0 5.477-4.457 9.93-9.93 9.93Zm5.445-7.438c-.297-.148-1.766-.87-2.039-.968-.273-.102-.473-.149-.672.148-.2.3-.77.973-.945 1.172-.172.195-.348.223-.645.074-.3-.148-1.261-.465-2.402-1.484-.887-.79-1.488-1.77-1.66-2.067-.176-.3-.02-.46.129-.61.136-.132.3-.347.449-.523.148-.171.2-.296.3-.496.098-.199.048-.375-.027-.523-.074-.148-.671-1.621-.921-2.219-.243-.582-.489-.5-.672-.511-.172-.008-.371-.008-.57-.008-.2 0-.524.074-.798.375-.273.297-1.043 1.02-1.043 2.488 0 1.469 1.07 2.89 1.22 3.09.148.195 2.105 3.21 5.1 4.504.712.308 1.266.492 1.7.629.715.226 1.367.195 1.883.12.574-.085 1.765-.722 2.015-1.421.247-.695.247-1.293.172-1.418-.074-.125-.273-.2-.574-.352Z"/></svg>}
            />
          </div>
        </Modal>

        <Modal visible={state.modal === 'invoice'} theme={theme} onClose={() => state.modal = null}>
          <form onSubmit={createInvoice} className="p-4 space-y-2 pb-0">
            <input type="text" name="company_name" value={state.data.company_name} onChange={({target: {value}}) => state.data.company_name = value} placeholder={t('company_name')} className={'block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300 rounded-md dark:text-white dark:bg-gray-950 '}/>
            <input type="text" name="address" value={state.data.address} onChange={({target: {value}}) => state.data.address = value} placeholder={t('address')} required className={'block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300 rounded-md dark:text-white dark:bg-gray-950 '}/>
            <div className="grid sm:grid-cols-2 gap-2">
              <input type="text" name="zipcode" value={state.data.zipcode} onChange={({target: {value}}) => state.data.zipcode = value} placeholder={t('zipcode')} required className={'block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300 rounded-md dark:text-white dark:bg-gray-950 '}/>
              <input type="text" name="city" value={state.data.city} onChange={({target: {value}}) => state.data.city = value} placeholder={t('city')} required className={'block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300 rounded-md dark:text-white dark:bg-gray-950 '}/>
            </div>

            <button type="submit" className={'p-3 bg-blue-600 rounded-md font-bold flex w-full justify-between items-center ' + (theme.primary_light ? 'text-black' : 'text-white')}  style={{backgroundColor: theme.primary_color}}>
              {t('download_invoice')}
            </button>
          </form>
        </Modal>

      </div>
  )

}

export default inject("DeviceStore")(observer(Order));
